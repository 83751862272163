import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

function Layout({ children }) {
  return (
    <>
      <span id="top" />
      <Navbar />
      {children}

      <div style={{ height: '100px' }} />
      <Footer />
    </>
  );
}

export default Layout;
