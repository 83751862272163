import React from 'react';
import logo from './OMT-symbol-white1.png';

function Navbar() {
  return (
    <nav className="navbar fixed-top justify-content-between">
      <a className="navbar-brand mx-auto" href="#top">
        <img src={logo} alt="OMT Logo" />
      </a>
    </nav>
  );
}

export default Navbar;
