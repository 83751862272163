import React from 'react';
import dylan from './dylan.jpeg';
import './index.css';
import jin from './jin.jpeg';
// import monica from './monica.jpeg';
import oliver from './oliver.jpeg';
import placeholder from './placeholder.jpg';

const Person = ({ name, image, title, subTitle }) => (
  <div className="card mb-4 shadow-sm person-card">
    <img
      src={image || placeholder}
      style={{ height: 'auto', width: '100%' }}
      alt={name}
    />
    <div className="card-body text-center">
      <h3 className="card-title">{name}</h3>
      <h4 className="title">{title}</h4>
      <h4 className="sub-title">{subTitle}</h4>
    </div>
  </div>
);

function People() {
  return (
    <section id="people" className="home-section container">
      <div className="text-center mt-4 mb-5">
        <h1>People</h1>
      </div>

      <div className="row justify-content-md-center">
        <div className="col col-md-4">
          <Person
            name="Oliver Mulelid-Tynes"
            title="President"
            image={oliver}
          />
        </div>
        {/*
        <div className="col col-md-4">
          <Person
            name="Monica Mulelid-Tynes"
            title="Interim CEO"
            image={monica}
          />
        </div>
        */}
      </div>
      <div className="row justify-content-md-center">
        <div className="col col-md-4">
          <Person
            name="Dylan Foster"
            title="Senior Developer"
            subTitle="Lead Engineer - Safari"
            image={dylan}
          />
        </div>
        <div className="col col-md-4">
          <Person
            name="Jin Park"
            title="Senior Developer"
            subTitle="Lead Engineer - Blink"
            image={jin}
          />
        </div>
      </div>
    </section>
  );
}

export default People;
