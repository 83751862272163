import React from 'react';
import Contact from '../../components/Contact';
import Layout from '../../components/Layout';
import People from '../../components/People';
import Projects from '../../components/Projects';
import videoMP4 from './Aloha-Mundo.mp4';
import videoOGV from './Aloha-Mundo.ogv';
import videoWEBM from './Aloha-Mundo.webm';
import './index.css';
import mobileFallback from './mobile-fallback.jpg';

function HomePage() {
  return (
    <Layout>
      <div className="cover-5 fullscreen-cover main-opacity-video">
        <div className="video-cover" />
        <div className="video-container">
          <video
            id="index-video"
            width="1920"
            height="1080"
            className="video-width-height"
            autoPlay
            loop
            playsInline
            poster={mobileFallback}
            muted
          >
            <source src={videoMP4} type="video/mp4" />
            <source src={videoOGV} type="video/ogv" />
            <source src={videoWEBM} type="video/webm" />
            Your browser does not support HTML5 video
          </video>
        </div>

        <div className="cover-headline middle center main-opacity-text container">
          <h1 className="light color-and-height h1-text-size">
            <p className="main-title-text">OMT Tech</p>
            We help companies transform great ideas into great products
          </h1>
        </div>
      </div>

      <People />
      <Projects />
      <Contact />
    </Layout>
  );
}

export default HomePage;
