import React from 'react';

import './index.css';

function Footer() {
  return (
    <footer>
      <div className="container">
        <strong>&copy; OMT Tech AS. All rights reserved.</strong>
      </div>
    </footer>
  );
}

export default Footer;
