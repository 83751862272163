import React from 'react';

import safariLogo from './safari-logo.png';
import blinkLogo from './blink-logo.png';

function Projects() {
  return (
    <section id="projects" className="home-section container">
      <h1 className="text-center mt-4 mb-5">Current Projects</h1>

      <div className="card mb-4 shadow-sm">
        <div className="card-body">
          <div className="row">
            <div className="col col-md-2">
              <img
                src={safariLogo}
                alt="safari project logo"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
            <div className="col">
              <h3 className="card-title">SafariDB &amp; V3Geo</h3>
              <p>
                <strong>SafariDB</strong> is an ongoing joint venture research
                project between
                <a
                  href="http://www.abdn.ac.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The University of Aberdeen
                </a>{' '}
                and{' '}
                <a
                  href="http://uni.no/en/uni-cipr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Uni Research CIPR
                </a>{' '}
                funded by a consortium of oil companies operating on the
                norwegian continental shelf,{' '}
                <a
                  href="http://www.forskningsradet.no/en/Home_page/1177315753906"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Research Council of Norway
                </a>{' '}
                and{' '}
                <a
                  href="http://www.npd.no/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Norwegian Petroleum Directorate
                </a>
                .
              </p>

              <p>
                <strong>V3Geo</strong> is a bespoke public repository and viewer
                for 3D virtual geoscience models, with a particular focus on
                virtual geological outcrops (VOs). The site has been
                specifically designed for geoscientists for the sharing of
                virtual 3D models within the geoscience community. The site
                includes over a hundred high quality virtual outcrop models from
                across the globe and is set to grow rapidly with a wide variety
                of 3D geoscience data.
              </p>

              <p style={{ marginBottom: 0 }}>
                To reach out to us regarding SafariDB or V3Geo, or for more
                information about the projects, please email{' '}
                <a href="mailto:dylan.foster@omt.tech">Dylan Foster</a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <div className="card-body">
          <div className="row">
            <div className="col col-md-2">
              <img
                src={blinkLogo}
                alt="blink project logo"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
            <div className="col">
              <h3 className="card-title">Bl;nk</h3>
              <p>
                Bl;nk is a joint venture between OMT Tech AS, Lyse AS,
                Westcontrol AS and Norsk Telemedisin AS. The project is also
                known as "video for alle" and centers around making accessible
                video devices to be used in medical care and other areas where a
                focus on privacy and usability is important.
              </p>

              <p style={{ marginBottom: 0 }}>
                To reach out to us regarding Bl;nk or for more information about
                the project, please email{' '}
                <a href="mailto:jin.park@omt.tech">Jin Park</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
