import React from 'react';

function Contact() {
  return (
    <section id="contact" className="home-section container">
      <h1 className="text-center mt-4 mb-5">Contact</h1>
      <div className="text-center">
        <p>
          For business development or general inquiries, please contact{' '}
          <a href="mailto:oliver@omt.tech">Oliver Mulelid-Tynes</a>.
        </p>
      </div>
    </section>
  );
}

export default Contact;
